export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "ArchiveIcon",
  },

  {
    title: "Profile",
    route: "profile",
    icon: "UserIcon",
  },
  {
    title: "Jobs",
    route: "company-job",
    icon: "BriefcaseIcon",
  },
  {
    title: "My Applications",
    route: "my-applications",
    icon: "PenToolIcon",
  },
  {
    title: "Portfolio Project",
    route: "portfolio-project",
    icon: "FileIcon",
  },
  {
    title: "Schedule Setting",
    route: "schedule-setting",
    icon: "CalendarIcon",
  },
  {
    title: "My Schedule",
    route: "schedule",
    icon: "CalendarIcon",
  },
];
